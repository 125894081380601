<template>
  <base-layout>
    <IonSegment :value="activeDay" scrollable style="justify-content: space-between">
      <IonSegmentButton @click="showRequests()"><span v-if="isDesktop">Requests </span>
        <ion-icon v-if="!isDesktop" :icon="listOutline"></ion-icon>
      </IonSegmentButton>
      <IonSegmentButton v-for="(value, key) in days" :key="key" :value="key" style="min-width:250px" mode="md"
                        @click="activeDay = key; isRequests=false; prepareGrid()">{{ value.label }}
      </IonSegmentButton>
    </IonSegment>
    <ion-content>
      <div v-if="!isRequests"
           class=" meeting-sessionGridComponent meeting-has-header {{ !disableChange ? 'has-subheader' : ''}}"
           v-bind:class="roomsOnHorizontal ? 'rooms-horizontal' : 'rooms-vertical'">
        <div style="margin-top: 400px; text-align: center" v-if="this.isConsultant==false"> You are not registered as an
          Exhibitor
        </div>
        <div v-if="isConsultant" class="meeting-session-grid-component"
             v-bind:style="sessionGridComponent(containerWidth)">
          <div class="meeting-corner-item" v-bind:style="cornerItem(disableChange, isIos)" v-if="!syncActive"></div>
          <div class="meeting-room-items">
            <div class="meeting-room-item"></div>
            <div v-for="item in this.mappedConsultants " :key="item">
              <a class="meeting-room-item" style="color: #000; text-decoration: none">{{ item.firstname }}
                {{ item.lastname }}</a>
            </div>
          </div>
          <div class="meeting-hours-grid">

            <div class="meeting-hour-items-row">
              <div class="meeting-hour-header-item" v-for="hour in hours" :key="hour">{{ hour }}:00</div>
            </div>
            <div v-for="item in this.mappedConsultants " :key="item" class="meeting-hour-items-row">
              <div v-for="hour in hours" :key="hour" class="meeting-hour-item"
                   v-bind:style="consultantColor(item)"></div>
              <template v-for="slot in appointments" :key="slot">
                <template v-if="slot.start_at.slice(0,10) == activeDay ">
                  <template v-if="slot.consultant_id ==item.id ">


                    <a v-if="!isLessThanNinety(slot.start_at, slot.end_at)" class="meeting-session-item"
                       v-bind:style="sessionPosition(slot)">
                      <div class="meeting-si-content" @click="activatedDay(slot.id)"
                           v-bind:style="siContent(slot.start_at, slot.end_at)">
                        <div style="padding-left: 5px"> {{ slot.subject }}</div>
                        <div class="meeting-si-time">{{ (moment(slot.start_at).utc(false).format("HH:mm")) }} -
                          {{ moment(slot.end_at).utc(false).format("HH:mm") }}
                        </div>
                        <div class="meeting-si-title">{{ slot.description }}</div>
                        <div class="meeting-si-title">{{ slot.requester_user_email }}</div>
                        <br>
                        <div style="padding-left: 5px"><b> Requester: </b> <br></div>
                        <div class="meeting-si-title">{{ slot.requester_user_email }}</div>

                        <div @click="cancelMeetingAlert(slot)" v-if="slot.id == activeDaySlot"
                             class="meeting-link cancel-meeting-button"> Cancel
                        </div>
                      </div>
                    </a>

                    <a v-if="isLessThanNinety(slot.start_at, slot.end_at)"
                       class="meeting-session-item session-item-less-ninety" v-bind:style="sessionPosition(slot)">
                      <div class="meeting-si-content" @click="activatedDay(slot.id)"
                           v-bind:style="siContent(slot.start_at, slot.end_at)">
                        <div style="padding-left: 5px"> {{ slot.subject }}</div>
                        <div class="meeting-si-time">{{ moment(slot.start_at).utc(false).format("HH:mm") }} -
                          {{ moment(slot.end_at).utc(false).format("HH:mm") }}
                        </div>
                        <div class="meeting-si-title">{{ slot.description }}</div>
                        <br>
                        <div style="padding-left: 5px"><b> Requester: </b> <br></div>
                        <div class="meeting-si-title">{{ slot.requester_user_email }}</div>


                        <div @click="cancelMeetingAlert(slot)" v-if="slot.id == activeDaySlot"
                             class="meeting-link cancel-meeting-button"> Cancel
                        </div>
                      </div>
                    </a>

                  </template>


                </template>
              </template>

            </div> <!-- changed break to breakUnit (break is reserved) -->

          </div>
          <div class="meeting-filler"></div>
        </div>
      </div>
      <RequestHandler v-if="isRequests"
                      :requestData="this.appointmentRequests"
      ></RequestHandler>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSegment, IonSegmentButton} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import config from '../../config'
import {isPlatform} from '@ionic/vue';
import RequestHandler from './RequestHandler.vue';
import {listOutline} from 'ionicons/icons';

const API_URL = config.appointment_api_url;
import axios from 'axios';

import {
  alertController,
} from "@ionic/vue";

export default defineComponent({
  name: 'MeetingsManager',

  setup() {
    return {
      listOutline,
    }
  },
  data() {
    return {
      isConsultant: false,
      activeDaySlot: null,
      isRequests: false,
      //APPOINTMENTS AND REQUESTS
      consultantsData: null,
      userExhibirtor: null,
      authUserData: null,
      exhibitorsArray: null,
      consultantsIdArray: [],
      appointmentsInfo: {},
      mappedConsultants: [],

      //appointmentsListRequester: [],
      user: null,
      noAppointments: false,
      minAppointment: false,

      //SCHEDULE AND SESSIONS
      sessions: null,
      days: {},
      activeDay: '',

      //GRID VARIABLES
      disableChange: true,
      isIos: null,
      widthMultiplier: 4, //60 * 2.3 = 140px
      heightMultiplier: 3, //60 * 1 = 60px
      hours: [],
      day: moment().date(), //check this later
      roomsOnHorizontal: true,
      format: '',
      gridDays: [],

      //GRID VARIABLES TO BE SET ONCE WE GOT THE SESSION GRID DATA.
      rooms: null,
      breaks: null,
      dayStart: null,
      dayEnd: null,
      blockSize: null,
      containerWidth: null,
      range: null,
    }
  },
  components: {
    IonContent,
    IonSegment,
    IonSegmentButton,
    RequestHandler
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive']),
    ...mapState('auth', ['user']),
    ...mapGetters('appointment', ['appointmentsByRequester', 'appointments', 'appointmentRequests']),
    ...mapGetters('consultant', ['consultantsByExhibitor']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),
  },
  methods: {
    ...mapActions('appointment', ['getAppointmentsByRequester']),
    ...mapActions('sessions', ['getDays']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('consultant', ['getConsultantsByExhibitor']),
    ...mapActions('appointment', ['getAppointments', 'getRequests']),
    ...mapActions('exhibitors', ['getAllExhibitors']),

    openMenu() {
      menuController.open("app-menu")
    },

    async cancelMeetingAlert(meeting) {
      console.log("cancel meeting")
      console.log(meeting)

      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Cancel Meeting',
            message: 'Are you sure? This action cannot be reverted',
            buttons: [
              {
                text: 'Go Back',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Confirm',
                id: 'confirm-button',
                handler: () => {
                  console.log('Confirm Okay')
                  this.cancelMeeting(meeting)
                },
              },
            ],
          });
      return alert.present();
    },


    async cancelMeeting(meeting) {
      console.log("cancelada")
      console.log(meeting)
      let id = meeting.id;
      let updateAppointment = {}
      updateAppointment.accepted = false;
      updateAppointment.rejected = true;

      for (let i = 0; i < this.mappedConsultants.length; i++) {
        console.log(this.mappedConsultants)
        if (this.mappedConsultants[i].id == meeting.consultant_id) {
          meeting.consultant = this.mappedConsultants[i]
        }
      }
      await axios.put(API_URL + "appointments/" + id, updateAppointment);
      await axios.post(API_URL + "rejectedMail/", meeting);

      await this.getAppointments(this.appointmentsInfo)
      await this.getRequests(this.requestsInfo)

    },

    sessionGridComponent(containerWidth) {
      return {
        'width': containerWidth + 'px',
      };
    },

    setActiveDayForToday() {

      for (let day in this.days) {
        if (day == moment().format('YYYY-MM-DD')) {
          this.activeDay = day;
        }
      }

      if (this.activeDay != null) {
        let daysArray = [];
        for (let d in this.days) {
          daysArray.push(d);
        }
        this.activeDay = daysArray[0]

      }

    },

    checkHour(date, endDate) {
      let nowDate = new Date();
      let mDateStart = new Date(date);
      let mDateEnd = new Date(endDate);

      if (nowDate >= mDateStart && nowDate <= mDateEnd) {
        return true
      } else {
        return false
      }
    },

    prepareAppointmentItemStyle(start_at, end_at) {
      let styles = {};
      if (this.checkHour(start_at, end_at)) { //SWAP ! FOR TESTING
        styles = {
          'pointer-events': 'none',
        }
      }

      return styles;
    },

    cornerItem(disableChange, isIos) {
      if (disableChange && !isIos) {
        return {
          'top': '98px',
        };
      }

      if (isIos) {
        return {
          'top': '92px !important',
        }
      } else {
        return {
          'top': '88px',
        }
      }
    },

    pStyle(blockSize) {
      return {
        'width': blockSize + 'px !important',
      }
    },

    siContent(start, end) {
      if (moment().utc(false).isBetween(moment(start).utc(false), moment(end).utc(false))) {
        return {
          'border-left-color': 'green',
        };
      } else if (moment().utc(false).isBefore(moment(start).utc(false))) {
        return {
          'border-left-color': 'orange',
        };
      } else {
        return {
          'border-left-color': 'red',
        };
      }
    },

    siCode(session) {
      let color = session.color;
      let fontColor = session.font_color;

      return {
        'background-color': color,
        'color': fontColor,
      };
    },

    isLessThanNinety(start, end) {
      let duration = moment.duration(moment(end).diff(moment(start)));
      if (duration.asMinutes() < 90) {
        return true;
      } else {
        return false;
      }
    },

    rotateView() {
      this.roomsOnHorizontal = !this.roomsOnHorizontal; //swap true to false 
      return true;
    },

    setGridData() {
      this.hours = []; //reset it on change, will have to check more variables to reset

      let startArray = [];
      let endArray = [];
      //I save all the starting and ending hours
      for (let i = 0; i < this.consultantsByExhibitor.length; i++) {
        startArray.push(this.consultantsByExhibitor[i].available_from.slice(0, 2))
        endArray.push(this.consultantsByExhibitor[i].available_until.slice(0, 2))
      }

      this.dayStart = Math.min.apply(Math, startArray);
      this.dayEnd = Math.max.apply(Math, endArray);

      for (let i = this.dayStart; i <= this.dayEnd; i++) {
        this.hours.push(i); //set the number of hours based on the difference between start and end
      }
      this.range = this.setRange();

      this.blockSize = this.consultantsByExhibitor.length > 3 ? this.widthMultiplier * 180 : this.widthMultiplier * 60
      this.containerWidth = this.consultantsByExhibitor.length * 140;
    },

    setRange() {
      let num = Math.ceil((Object.keys(this.consultantsByExhibitor).length) / 3);
      return new Array(num);
    },

    activatedDay(slotId) {
      if (this.activeDaySlot == slotId) {

        this.activeDaySlot = null;
      } else {
        this.activeDaySlot = slotId;
      }

    },

    sessionPosition(item) {
      let startTmp = item.start_at.slice(11, 19);
      let startTmpHours = parseInt(startTmp.split(':')[0])
      let startTmpMinutes = parseInt(startTmp.split(':')[1])
      let endTmp = item.end_at.slice(11, 19);
      let endTmpHours = parseInt(endTmp.split(':')[0])
      let endTmpMinutes = parseInt(endTmp.split(':')[1])

      let startTime = startTmpHours;
      let endTime = endTmpHours;
      //startTime = new Date(item.start_time);
      //endTime = new Date(item.end_time);
      let dimension = this.roomsOnHorizontal ? 'height' : 'width';
      let translate = this.roomsOnHorizontal ? 'translateY(' : 'translateX(';
      let pixelMultiplier = this.roomsOnHorizontal ? this.heightMultiplier : this.widthMultiplier;

      let duration = moment.duration(moment(item.end_at).diff(moment(item.start_at)));

      if (item.id == this.activeDaySlot && duration.asMinutes() < 60) {
        return {
          [dimension]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplier + 'px',
          'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',
          'height': '230px !important',
          'z-index': '30',
          'opacity': '0.95',
        };
      } else {
        return {
          [dimension]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplier + 'px',
          'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',
        }
      }
    },

    showRequests() {
      this.isRequests = true;
    },

    consultantColor(consultant) {
      let colorsArray = ['#B9D2ED', '#B3DFF2', '#ADD8DB', '#B3F2E7', '#ACE8CE']; //5 colors
      let consultantNumber = this.consultantsByExhibitor.indexOf(consultant);
      while (consultantNumber > 4) { //infinite loop to get numbers always from 0 to 4
        consultantNumber = consultantNumber - 5;
      }
      return {
        'background-color': colorsArray[consultantNumber],
      };
    },

    async prepareGrid() {
      await this.getConsultantsByExhibitor(this.userExhibitor[0].id)

      this.mappedConsultants = this.consultantsByExhibitor.filter(function (obj) {
        return obj.disabled !== true;
      });

      this.consultantsIdArray = this.mappedConsultants.map(a => a.id);

      this.appointmentsInfo = {
        'ConsultantArray': this.consultantsIdArray,
        'Conference': this.currentConference,
        'Accepted': true
      };
      await this.getAppointments(this.appointmentsInfo)
      this.requestsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.currentConference};
      await this.getRequests(this.requestsInfo)

      this.setGridData();

    },
  },


  async created() {
    this.isDesktop = isPlatform('desktop');
    this.isIos = isPlatform('ios')
    this.user = await this.$store.dispatch('auth/GET_USERDATA');
    await this.getAppointmentsByRequester({"ConferenceId": this.currentConference, "UserId": this.user.data.id});
    this.moment = moment;
    this.days = await this.getDays();
    this.setActiveDayForToday();
    this.exhibitorsArray = await this.getAllExhibitors()

    this.userExhibitor = this.exhibitorsArray.filter(ex => ex.email == this.user.data.emailBusiness)

    if (this.userExhibitor.length > 0) {
      this.prepareGrid()
      this.isConsultant = true;
    }

  },

});
</script>
<style lang="scss">

</style>
