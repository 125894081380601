<template>

<div>
 <ion-header translucent>
      <ion-toolbar class="toolbar-title">
        <ion-title>Appointment Requests</ion-title>
       <ion-buttons slot="end">
          <ion-button @click="refreshRequests()">
            <ion-icon :icon="reloadOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ion-grid v-if="desktop">
       
        <ion-row class="request-table-title"  >
            <ion-col> 
              <div>Subject</div>
            </ion-col>
            <ion-col>
              <div >Requester Email</div>
            </ion-col>
            <ion-col>
              <div>Consultant</div>
            </ion-col>
            <ion-col>
              <div>Date</div>
            </ion-col>
            <ion-col>
              <div>Info</div>
            </ion-col>
         </ion-row>
          <ion-row v-if="requestData.length<1 " class="no-requests"> <!--not working will take a look-->
        <ion-col>
            <div>There are currently no requests. Try again later</div>
        </ion-col>
        </ion-row>

        <ion-row v-for="(request, key) in requestData" :key="key" class="request-row">
           <template v-if="!request.consultant.disabled || request.consultant.disabled == null">
             <ion-col class="request-col">
              <div class="request-text">{{parseSubject(request.subject)}}</div>
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text "><a class="requester-email" :href = "'mailto:' + request.requester_user_email">{{request.requester_user_email}}</a></div>
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text">{{request.consultant.firstname}} {{request.consultant.lastname}}</div>
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text"> {{getFormattedRequestDate(request.start_at).day}} <br v-if="!desktop"> {{getFormattedRequestDate(request.start_at).hour}} </div>
            </ion-col>
            <ion-col>

                 <button class="request-info-button"  @click="openRequestModal(request)">
                   <ion-icon :icon="informationOutline"></ion-icon>
                </button>

            </ion-col>
             </template>
        </ion-row>    

        <button class="edit-consultants-button" @click="openConsultantsModal()">
          Manage Consultants<ion-icon style="margin-left: 10px; " :icon="constructOutline"></ion-icon>
        </button>

       <!-- <ion-button class="consultants-button" size="default" @click="openConsultantsModal()" color="secondary">
                    Manage Consultants<ion-icon style="margin-left: 10px; " :icon="constructOutline"></ion-icon>
        </ion-button> -->

        <ion-row v-for="(request, key) in requestData" :key="key" class="request-row parent-row">
          <template v-if="request.consultant.disabled">
            <div class="disabled-tag"> <ion-icon class="disabled-icon" :icon="informationCircleOutline"></ion-icon> <span class="disabled-icon"> DISABLED </span> </div>
             <ion-col class="request-col">
              <div class="request-text-disabled">{{parseSubject(request.subject)}}</div>
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text-disabled"><a :href = "'mailto:' + request.requester_user_email">{{request.requester_user_email}}</a></div>
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text-disabled">{{request.consultant.firstname}} {{request.consultant.lastname}}           
              </div>
           
            </ion-col>
            <ion-col class="request-col">
              <div class="request-text-disabled"> {{getFormattedRequestDate(request.start_at).day}} <br v-if="!desktop"> {{getFormattedRequestDate(request.start_at).hour}} </div>
            </ion-col>
            <ion-col>
  
                 <button class="request-info-button button-disabled"  disabled>
                   <ion-icon :icon="informationOutline"></ion-icon>
                </button>
                
            </ion-col>
           
          </template>
        </ion-row>       


      </ion-grid>

      <!-- PHONE VERSION -->

      <ion-grid v-if="!desktop">
       
          <ion-row v-if="requestData.length<1 " class="no-requests"> <!--not working will take a look-->
        <ion-col>
            <div>There are currently no requests. Try again later</div>
        </ion-col>
        </ion-row>
        
        <!-- PHONE ENABLED --->
        <div v-for="(request, key) in requestData" :key="key" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-top: 15px;">
            <template v-if="!request.consultant.disabled">
             <ion-row class="parent-row">         
                <ion-col>
                  <div class="request-table-title subject">{{parseSubject(request.subject)}}</div>
                </ion-col>
             </ion-row>

             <ion-row>
                <ion-col  class="request-table-title mobile">
                  Requester:
                </ion-col>
                <ion-col class="request-row-phone">
                  <a :href = "'mailto:' + request.requester_user_email">{{request.requester_user_email}}</a>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col class="request-table-title mobile">
                  <div>Consultant:</div>
                </ion-col>
                <ion-col class="request-row-phone">
                  <div>{{request.consultant.firstname}} {{request.consultant.lastname}}</div>
                </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="request-row-phone">
                <div style="text-align: center;"> {{getFormattedRequestDate(request.start_at).day}} - {{getFormattedRequestDate(request.start_at).hour}} </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                  <button class="request-info-button"  @click="openRequestModal(request)">
                   <ion-icon :icon="informationOutline"></ion-icon>
                </button>
              </ion-col>
            </ion-row>
            </template>
        </div>    

         <!-- PHONE DISABLED --->
        <div v-for="(request, key) in requestData" :key="key" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-top: 15px;">
            <template v-if="request.consultant.disabled">
             <ion-row class="parent-row">
                <div style="left: auto; right: 10px; top: 13px; text-align: center; z-index: 3; " class="disabled-tag"> <ion-icon class="disabled-icon" :icon="informationCircleOutline"></ion-icon> <span class="disabled-icon"> DISABLED </span> </div>
                <ion-col>
                  <div class="request-table-title subject">{{parseSubject(request.subject)}}</div>
                </ion-col>
             </ion-row>

             <ion-row>
                <ion-col  class="disabled-text request-table-title mobile">
                  Requester:
                </ion-col>
                <ion-col class="request-row-phone">
                  <a :href = "'mailto:' + request.requester_user_email">{{request.requester_user_email}}</a>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col class="request-table-title mobile">
                  <div class="disabled-text">Consultant:</div>
                </ion-col>
                <ion-col class="request-row-phone">
                  <div class="disabled-text">{{request.consultant.firstname}} {{request.consultant.lastname}}</div>
                </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="request-row-phone">
                <div class="disabled-text" style="text-align: center;"> {{getFormattedRequestDate(request.start_at).day}} - {{getFormattedRequestDate(request.start_at).hour}} </div>
              </ion-col>
            </ion-row>

            <ion-row>
        
              <ion-col>
                  <button class="request-info-button button-disabled"  disabled>
                   <ion-icon :icon="informationOutline"></ion-icon>
                </button>
              </ion-col>

            </ion-row>
            </template>
        </div>  

        
         <button class="edit-consultants-button" @click="openConsultantsModal()">
          Manage Consultants<ion-icon style="margin-left: 10px; " :icon="constructOutline"></ion-icon>
        </button>



      </ion-grid>
    </ion-content>


</div>
</template>

<script>
import {defineComponent} from 'vue';
import { IonCol, IonGrid, IonRow, modalController } from "@ionic/vue";
import {reloadOutline, informationOutline, informationCircleOutline, constructOutline} from 'ionicons/icons';
import { isPlatform } from '@ionic/vue';
import RequestModal from "./RequestModal.vue";
import ConsultantsModal from "./ConsultantsModal.vue";
import {mapActions, mapGetters, mapState} from 'vuex';


export default defineComponent({
  name: 'RequestHandler',
  data() {
    return {
        desktop: null,
        consultantsIdArray: [],
        appointmentsInfo : {},
        requestsInfo : {}
    }
  },
  props: ["requestData"],
  setup() {
    const openRequestModal = async (request) => {
      const modal = await modalController.create({
        component: RequestModal,  
        componentProps: {
						requestSent: request,
        },
      });
      return modal.present();
    };

     const openConsultantsModal = async () => {
      const modal2 = await modalController.create({
        component: ConsultantsModal,  
        cssClass: 'wideModal'
      });
      return modal2.present();
    };

    return {
        reloadOutline,
        informationOutline,
        informationCircleOutline,
        constructOutline,
        openRequestModal,
        openConsultantsModal,
    }
    
},
  components: {
      IonCol, IonGrid, IonRow
  },
  computed: {
    ...mapGetters('appointment',['appointmentsByRequester', 'appointments', 'appointmentRequests']),
    ...mapGetters('consultant',['consultantsByExhibitor']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),

  },
  methods: {
    ...mapActions('consultant',['getConsultantsByExhibitor']),
    ...mapActions('appointment',['getAppointments', 'getRequests']),
    ...mapActions('exhibitors', ['getAllExhibitors']),

      getFormattedRequestDate(date){
                let fullhour = {};
                fullhour.day = date.slice(0, 10)
                fullhour.hour = date.slice(10, 16)
                const regEx = new RegExp('-', "g");
                fullhour.day = fullhour.day.replace(regEx, '/')
                return fullhour;
             },

      async refreshRequests(){
         await this.getAppointments(this.appointmentsInfo)
         await this.getRequests(this.requestsInfo)
      },

      parseSubject(subject){
        if (subject.length>35){
          return subject.slice(0, 35)+"..."
        } else {
          return subject
        }
      }
  },
  async created(){
      this.desktop= isPlatform('desktop')

    if(localStorage.getItem('x-api-key') != ''){
      let userRequest = await this.$store.dispatch("auth/GET_USERDATA");
      this.user = userRequest.data

    this.exhibitorsArray =  await this.getAllExhibitors()
    //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+this.conferenceId+"/exhibitors")).data //get all exs of conference
    this.userExhibitor = this.exhibitorsArray.filter(ex=>ex.email == this.user.emailBusiness) 
    await this.getConsultantsByExhibitor(this.userExhibitor[0].id)
    this.consultantsIdArray = this.consultantsByExhibitor.map(a => a.id);
    this.appointmentsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.currentConference, 'Accepted': true };
    this.requestsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.currentConference};

    this.refreshRequests();
  }
   }

});
</script>


<style lang="scss">
</style>
